import React, {Component} from 'react';
import {
  ActivityBar,
  ActivityBarFill,
  ActivityContainer,
  BodyMassContainer,
  BorderBottom,
  BoxHeader,
  ChartBarContainer,
  PercentageContainer,
  // PercentageContainer
} from "./styles";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import _ from 'lodash';
import { PercentageIcon } from '../../utils/icons';
// import LazyImage from '../common/LazyImage/LazyImage';
// import { ImageUrl } from '../../utils/constants';


class BodyMass extends Component{

  updateFillColor = (payload) => payload?.map((item) => ({
    ...item,
    fillColor: item.title === 'Underweight' ? '#76AB78' :
      item.title === 'Normal' ? '#F1C977' :
        item.title === 'Overweight' ? '#AF87C1' :
          item.title === 'Obese' ? '#F4AAA9' :
            item.fillColor
  }));


  render() {
    const {bodyMassPayload, isStyle,averagePercentage } = this.props;
    const updatedPayload = this.updateFillColor(bodyMassPayload);
    return(
      <BodyMassContainer>
        <BoxHeader>
          <div>
             Body Mass Index (BMI)   
          </div>
          <div className="averagePercentage">Avg : {averagePercentage[0]?.average_point}</div>
        </BoxHeader>
        <BorderBottom/>
        <ChartBarContainer bodyMassIndex>
          {
            !_.isNull(updatedPayload[0]) && updatedPayload.map((item, index) => (
              <ActivityContainer isMargin={'8px 0px'} key={index} color={'rgba(0, 92, 135, 0.60)'}>
                <div className="chartTitle">
                  {item.title}
                </div>
                <div className={ isStyle ? "chartSubTitle1" : "chartSubTitle"}>{item.subTitle}</div>
                <ActivityBar style={{ display: 'flex', alignItems: 'center' }} barHeight="28px" isRadius="0px" fillColor="rgba(33, 153, 193, 1)" backgroundFill={'rgba(0, 92, 135, 0.05)'}>
                  <ActivityBarFill isRadius="0px 4px 4px 0px"  isPadding="0px 2px"  width={item.fill} fillColor={item.fillColor}>
                  </ActivityBarFill>
                  {/* <PercentageContainer left={(item.fill)}>
                    {Number(item.fill.replace(/%/g, '')) >= 0 ? item.fill : ''}
                  </PercentageContainer> */}
                  <PercentageContainer isMargin={Number(item.fill.replace(/%/g, '')) >= 30 ? '0px' : '10px'} left={(item.fill)}>
                    {Number(item.fill.replace(/%/g, '')) >= 30 ?
                      <div className="icon-container">
                        {PercentageIcon(item.fillColor)}
                        <div style={{ marginLeft: '-38px' }}>{item.fill}</div>
                      </div>
                      : item.fill}
                  </PercentageContainer>
                </ActivityBar>
              </ActivityContainer>
            ))
          }
        </ChartBarContainer>
      </BodyMassContainer>
    )
  }
}

BodyMass.propTypes = {
  bodyMassPayload: PropTypes.array,
  isStyle : PropTypes.bool,
  averagePercentage:PropTypes.array,
};

const mapStateToProps = (state) => ({
  bodyMassPayload: state.companyDashboard.bodyMassDetails,
  averagePercentage:state.companyDashboard.bodyMassAveragePercentage,
});

export default connect(mapStateToProps, null)(BodyMass);