import React, {Component} from 'react';
import {
  GoodCholesterolContainer,
  SmallBoxHeaderV1,
  BorderBottom
} from "./styles";

import { QuestionsCard,AnswerWrapper } from '../HealthTabV2/AssesmentAndCategory/styles';
import PropTypes from "prop-types";
// import _ from 'lodash';
import DOMPurify from 'dompurify';
import styled from 'styled-components';
// import CircularProgressBar from '../HealthTabV2/HealthTabUpdated/circularProgressBar';
import VerticalBarChart from '../VerticalBarChart/VirticalBarChart';

const SanitizedHeaderTitle = styled.div``;

export default class Cholesterol extends Component{
  constructor(){
    super();
    this.headerTitleRef = React.createRef();
    this.state = {
      options: ["2019","2018","2017"],
      selectedIndex: 0,
      selectedValue: 2019
    }
  }

  componentDidMount(){
    this.setSanitizedHeaderTitle();
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.headerTitle !== this.props?.headerTitle) {
      this.setSanitizedHeaderTitle();
    }
  }

  setSanitizedHeaderTitle() {
    if (this.headerTitleRef.current) {
      const sanitizedHeaderTitle = DOMPurify.sanitize(this.props?.headerTitle);
      const fragment = document.createRange().createContextualFragment(sanitizedHeaderTitle);
      while (this.headerTitleRef.current.firstChild) {
        this.headerTitleRef.current.removeChild(this.headerTitleRef.current.firstChild);
      }
      this.headerTitleRef.current.appendChild(fragment);
    }
  }

  toggleRadioBtn = (index) =>{
    this.setState({
      selectedIndex: index,
      selectedValue: this.state.options[index],
      options: this.state.options
    });
  };

  graphRenderGoodCholesterol = (data) => {
    // const emptyObject = [
    //   { title: '', subTitle: '[0 - 0]', fill: '0', fillColor: '#ffff', backgroundFill: '#ffff' },
    //   { title: '', subTitle: '[0 - 0]', fill: '0', fillColor: '#ffff', backgroundFill: '#ffff' },
    // ];

    const updatedData = [...data];

    // window.console.log("updatedData :- ", updatedData);
    
    const topActivities = updatedData.map((item) => ({
      y: parseFloat(item.fill.replace('/%/g', '')) || 0, 
      color: item.fillColor || 'gray',
    }));
  
    const xAxisLabels = ['Optimal <br><span style="color: rgba(0, 92, 135, 0.30)"> [67+] </span>', 'Good <br><span style="color: rgba(0, 92, 135, 0.30)">[60-65]</span>', 'Low-Normal <br><span style="color: rgba(0, 92, 135, 0.30)">[41-59]</span>','Very Low <br><span style="color: rgba(0, 92, 135, 0.30)">[30-40]</span>', 'At Risk <br><span style="color: rgba(0, 92, 135, 0.30)">[<30]</span>'];
    const yAxisLabels = [ '20%', '40%', '60%','80%','100%'];
    const dataValue = [{
      name: '',
      data: topActivities,
      showInLegend: false,
      color: '#DE9444',
    }];
  
    return (
      <div className="bargraph">
        <VerticalBarChart
          id={`good`}
          seriesData={dataValue}
          xAxisLabel={xAxisLabels}
          yAxisVisible={yAxisLabels}
          fillColor={1}
          cultureSurvey={1}
          isMargin={true}
        />
      </div>
    );
  }
  

  graphRenderBadCholesterol = (data) => {

    const topActivities = data.map((item) => ({
      y: parseFloat(item.fill.replace('/%/g', '')) || 0,
      color: item.fillColor || 'gray',
    }));
  
    const xAxisLabels = ['Optimal <br><span style="color: rgba(0, 92, 135, 0.30)">[0-99]</span>', 'Near Optimal <br><span style="color: rgba(0, 92, 135, 0.30)">[100-129]</span>', 'Borderline High <br><span style="color: rgba(0, 92, 135, 0.30)">[130-159]</span>', 'High <br><span style="color: rgba(0, 92, 135, 0.30)">[160-189]</span>', 'At Risk <br><span style="color: rgba(0, 92, 135, 0.30)">[190+]</span>'];
    const yAxisLabels = ['20%', '40%', '60%', '80%', '100%'];
    const dataValue = [{
      name: '',
      data: topActivities,
      showInLegend: false,
      color: '#DE9444',
    }];
  
    return (
      <div className="bargraph">
        <VerticalBarChart
          id={`bad`}
          seriesData={dataValue}
          xAxisLabel={xAxisLabels}
          yAxisVisible={yAxisLabels}
          fillColor={1}
          cultureSurvey={1}
          isMargin={true}
        />
      </div>
    );
  }
  
  updateBadCholesterolPayload = (payload) => payload.map((item) => ({
    ...item,
    // fillColor: item.title === 'Optimal' ? '#76AB78' :
    //   item.title === 'Near Optimal' ? '#F1C977' :
    //     item.title === 'Borderline High' ? '#85C0EA' :
    //       item.title === 'High' ? '#AF87C1' :
    //         item.title === 'Very High' ? '#F4AAA9' :
    //           item.fillColor
  }))


  updateGoodCholesterolPayload = (payload) => payload.map((item) => ({
    ...item,
    fillColor: item.title === 'Lowers your risk' ? '#76AB78' :
      item.title === 'Normal' ? '#F1C977' :
        item.title === 'Very High' ? '#F4AAA9' :
          item.fillColor
  }))

  render() {
    const {marginLeft, marginRight,  childNumber, /* headerTitle, */payload ,isCholesterol,averagePercentage } = this.props;
    const updatedPayload = isCholesterol ? this.updateGoodCholesterolPayload(payload) : this.updateBadCholesterolPayload(payload)  ;
    return(
      <GoodCholesterolContainer marginLeft={marginLeft} marginRight={marginRight} childNumber={childNumber}  isShadow={"none"}>
        <SmallBoxHeaderV1 isBackground="white">
          <SanitizedHeaderTitle ref={this.headerTitleRef} />
          <div className='averagePercentage'>Avg : {averagePercentage[0]?.average_point}</div>
        </SmallBoxHeaderV1>
        <BorderBottom/>
        { isCholesterol ? 
          (<QuestionsCard
            // key={index}
            width={true}
            widthValue={"100%"}
            type={3}
            marginBottom={'0px'}
          >  
            <div className="body">
              <div className="answers" >
                <AnswerWrapper padding = "20px 35px 0px 10px">
                  {this.graphRenderGoodCholesterol(
                    updatedPayload 
                  )}
                </AnswerWrapper>
              </div>
            </div>
          </QuestionsCard> )  :

          (<QuestionsCard
          // key={index}
            width={true}
            widthValue={"100%"}
            type={3}
            marginBottom={'0px'}
          >  
            <div className="body">
              <div className="answers">
                <AnswerWrapper padding ="20px 35px 0px 10px">
                  {this.graphRenderBadCholesterol(
                    updatedPayload 
                  )}
                </AnswerWrapper>
              </div>
            </div>
          </QuestionsCard>)}
      </GoodCholesterolContainer>
    )
  }
}

Cholesterol.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  marginLeft: PropTypes.string.isRequired,
  marginRight: PropTypes.string.isRequired,
  childNumber: PropTypes.string.isRequired,
  payload: PropTypes.array.isRequired,
  isCholesterol: PropTypes.bool,
  averagePercentage:PropTypes.array,
  // averagePercentage1:PropTypes.array,
};